import { COOKIES } from '~/constants'
import type { EXTERNAL_AUTHENTICATION_PROVIDERS } from '~/constants/authentication'

type Credentials = {
  username?: string
  password?: string
}

export default function useAuthentication() {
  const { $captureError } = useNuxtApp()
  const runtimeConfig = useRuntimeConfig()
  const { data, status, token, signIn, signOut, getSession } = useAuth()
  const { setToken, clearToken } = useAuthState()
  const localePath = useLocalePath()
  const { routeBaseName } = useBaseName()

  /**
   * Watch for the user status to change.
   * Computed is lazy and isn't reacting as qucikly as we need it to.
   */
  const isLoggedIn = ref(status.value === 'authenticated')
  watch(status, (value) => {
    isLoggedIn.value = value === 'authenticated'
  })

  /**
   * Logs in the user.
   * @param credentials
   * @param token
   */
  async function authenticate({
    credentials,
    token,
    isSignup,
    callbackFn,
  }: {
    credentials?: Credentials
    token?: string
    isSignup?: boolean
    callbackFn?: () => void
  }) {
    if (!credentials && !token) {
      return
    }

    try {
      if (credentials) {
        await signIn(credentials, { redirect: false })
      }
      else if (token) {
        setToken(token)
        await getSession()
      }

      hideAuthModal()

      if (callbackFn) {
        callbackFn()
        return
      }

      const isNewOwner = Boolean(data.value?.UserType === 'Owner' && isSignup)
      await redirectAfterAuthentication(isNewOwner)
    }
    catch (err: unknown) {
      $captureError(err)
      /**
       * Throwing the error so we display the right error message in the UI.
       */
      throw err
    }
  }

  async function redirectAfterAuthentication(isNewOwner: boolean) {
    const unauthorizedRedirectCookie = useCookie(COOKIES.unauthorizedRedirect, {
      domain: runtimeConfig.public.cookieDomain,
      path: '/',
    })

    /**
     * Intent cookie is set when we want to trigger an action after the user authenticates with an external provider.
     */
    const intentCookie = useCookie(COOKIES.risIntent, {
      domain: runtimeConfig.public.cookieDomain,
      path: '/',
    })
    /**
     * Redirect URL cookie is set as the return URL after the user authenticates with an external provider.
     */
    const redirectURLCookie = useCookie(COOKIES.risRedirect, {
      domain: runtimeConfig.public.cookieDomain,
      path: '/',
    })

    // Copy the values to local variables and clear the cookies
    const unauthorizedRedirect = unauthorizedRedirectCookie.value
    unauthorizedRedirectCookie.value = null
    const intent = intentCookie.value
    intentCookie.value = null
    const redirectURL = redirectURLCookie.value
    redirectURLCookie.value = null

    /**
     * 1. Redirect authenticated user to the requested unauthorized URL.
     * 2. Redirect new owner to the RV listing flow.
     * 3. Redirect owner to the dashboard.
     * 4. Redirect authenticated user to the previous URL before external provider redirection.
     * 5. Redirect authenticated user to the homepage.
     */
    let path = unauthorizedRedirect

    if (!path) {
      const isOwnerPage = routeBaseName.value === 'owner' || redirectURL?.startsWith(localePath('owner'))
      if (isNewOwner || isOwnerPage) {
        // External should be set to true up until the New listing flow is implemented in Nuxt 3
        path = localePath('/dashboard/rvs/new')
      }
      else if (data.value?.IsOwner) {
        // External should be set to true up until the dashboard is implemented in Nuxt 3
        path = localePath('dashboard')
      }
      else {
        path = redirectURL
      }
    }

    /**
     * If path is set,
     * augment url with intent query parameter
     * and navigate to
     */
    if (path) {
      await navigateTo(
        {
          path,
          query: { intent: intent || undefined },
        },
        { external: true },
      )
    }
  }

  /**
   * Logs out a user (removes their cookie) and clears their list of RV
   * collections.
   */
  async function logOut(options?: { callbackUrl?: string, external?: boolean }) {
    clearToken()

    const risProviderCookie = useCookie(COOKIES.risProvider, {
      domain: runtimeConfig.public.cookieDomain,
      path: '/',
    })

    /**
     * RVezy Identity Service (RIS) provider cookie is set when the user logs in with an External Provider.
     */
    if (risProviderCookie.value) {
      const { externalDeauthenticate } = useExternalAuthentication()
      await externalDeauthenticate(risProviderCookie.value as EXTERNAL_AUTHENTICATION_PROVIDERS)

      // Remove the `ris-provider` cookie
      risProviderCookie.value = null
    }

    const { callbackUrl, external = true } = { ...options }
    await signOut({ callbackUrl: callbackUrl || localePath('index'), external })
    const brazeApi = await useBraze()
    brazeApi.reset()
  }

  const authenticationProvider = useAuthenticationProvider()

  const authenticationModalShown = useAuthenticationModal()

  /**
   * Watch for the authentication modal to be hidden and clear the unauthorized redirect cookie.
   */
  watch(authenticationModalShown, (value) => {
    if (!value) {
      useCookie(COOKIES.unauthorizedRedirect, {
        domain: runtimeConfig.public.cookieDomain,
        path: '/',
      }).value = null
    }
  })

  function hideAuthModal() {
    authenticationModalShown.value = null
  }

  const authenticationIntent = useAuthenticationIntent()

  return {
    user: data,
    status,
    token,
    isLoggedIn,
    hideAuthModal,
    authenticationIntent,
    authenticationModalShown,
    authenticationProvider,

    authenticate,
    logOut,
  }
}
