import dayjs from '~/lib/useDates.js'

/**
 * This file exposes frequently used formatters for Dayjs in human readable form.
 *
 * These functions are auto-imported in components and pages and can be used
 * directly in templates, <script setup> or anywhere in the Options API without
 * having to manually import them.
 */

/**
 * @example
 * <p>{{ formatAsFullDateLong('2021-01-01') }}</p> <!-- January 1, 2021 -->
 */
export const formatAsFullDateLong = (date: string | number | Date | dayjs.Dayjs) => dayjs(date).format('LL')

/**
 * @example
 * <p>{{ formatAsShortDateLong('2021-01-01') }}</p> <!-- Jan 1, 2021 -->
 */
export const formatAsShortDateLong = (date: string | number | Date | dayjs.Dayjs) => dayjs(date).format('ll')

/**
 * @example
 * <p>{{ formatAsShortDateWithTime('2021-01-01') }}</p> <!-- Jan 1, 2021 12:00 AM -->
 */
export const formatAsShortDateWithTime = (date: string | number | Date | dayjs.Dayjs) => dayjs(date).format('lll')

/**
 * @example
 * <p>{{ formatAsOrdinalDateLong('2021-01-01') }}</p> <!-- January 1st 2021 -->
 */
export const formatAsOrdinalDateLong = (date: string | number | Date | dayjs.Dayjs) =>
  dayjs(date).format('MMMM Do YYYY')

/**
 * @example
 * <p>{{ formatAsShortDate('2021-01-01') }}</p> <!-- Jan 1 -->
 */
export const formatAsShortDate = (date: string | number | Date | dayjs.Dayjs) => dayjs(date).format('MMM D')

/**
 * @example
 * <p>{{ formatAsShortDateYear('2021-01-01') }}</p> <!-- Jan 1, 2021 -->
 */
export const formatAsShortDateYear = (date: string | number | Date | dayjs.Dayjs) => dayjs(date).format('MMM D, YYYY')

/**
 * @example
 * <p>{{ formatAsDateISO('2021-01-01') }}</p> <!-- 2021-01-01 -->
 */
export const formatAsDateISO = (date: string | number | Date | dayjs.Dayjs) => dayjs(date).format('YYYY-MM-DD')

/**
 * @example
 * <p>{{ formatAsYearOnly('2021-01-01') }}</p> <!-- 2021 -->
 */
export const formatAsYearOnly = (date: string | number | Date | dayjs.Dayjs) => dayjs(date).format('YYYY')

/**
 * @example
 * <p>{{ formatAsDateTimeExtended('2021-01-01') }}</p> <!-- 2021-01-01T12:00:00.000+00:00 -->
 */
export const formatAsDateTimeExtended = (date: string | number | Date | dayjs.Dayjs) =>
  dayjs(date).format('YYYY-MM-DDT12:00:00.000Z')

/**
 * @example
 * <p>{{ formatAsDayOfWeek('2021-01-01') }}</p> <!-- Friday -->
 */
export const formatAsDayOfWeek = (date: string | number | Date | dayjs.Dayjs) => dayjs(date).format('dddd')

/**
 * @example
 * <p>{{ formatAsTime('2021-01-01') }}</p> <!-- 12:00 AM -->
 */
export const formatAsTime = (date: string | number | Date | dayjs.Dayjs) => dayjs(date).format('h:mm A')

/**
 * @example
 * <p>{{ formatAsMonthYear('2021-01-01') }}</p> <!-- Jan 2021 -->
 */
export const formatAsMonthYear = (date: string | number | Date | dayjs.Dayjs) => dayjs(date).format('MMM YYYY')

/**
 * @example
 * <p>{{ formatAsDayAndMonth('2021-01-01') }}</p> <!-- Friday, Jan 1 -->
 */
export const formatAsDayAndMonth = (date: string | number | Date | dayjs.Dayjs) => dayjs(date).format('dddd, MMM D')

/**
 * @example
 * <p>{{ formatAsDateTimeOffset('2021-01-01') }}</p> <!-- 2021-01-01T00:00:00+00:00 -->
 */
export const formatAsDateTimeOffset = (date: string | number | Date | dayjs.Dayjs) =>
  dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ')

/**
 * @example
 * <p>{{ formatAsTimeZone('2021-01-01') }}</p> <!-- EST -->
 */
export const formatAsTimeZone = (date: string | number | Date | dayjs.Dayjs) => dayjs(date).format('z')

/**
 * @example
 * <p>{{ formatAsHour24('2021-01-01') }}</p> <!-- 0 -->
 */
export const formatAsHour24 = (date: string | number | Date | dayjs.Dayjs) => dayjs(date).format('H')

/**
 * @example
 * <p>{{ formatAsHour12Abbreviated('2021-01-01') }}</p> <!-- 12am -->
 */
export const formatAsHour12Abbreviated = (date: string | number | Date | dayjs.Dayjs) => dayjs(date).format('ha')

/**
 * @example
 * <p>{{ translateTime(9) }}</p> <!-- 9:00 AM -->
 */
export const translateTime = (value: number, formatHourIsUppercase = true) => dayjs().hour(value).minute(0).second(0).format(`h:mm ${formatHourIsUppercase ? 'A' : 'a'}`)
