import { createPopper } from '@popperjs/core'

type Modifiers = {
  top?: boolean
  bottom?: boolean
  left?: boolean
  right?: boolean
  html?: boolean
}

const MODIFIER_KEYS = {
  top: 'top',
  bottom: 'bottom',
  left: 'left',
  right: 'right',
} as const
export type MODIFIER_KEYS = (typeof MODIFIER_KEYS)[keyof typeof MODIFIER_KEYS]

export default defineNuxtPlugin((nuxtApp) => {
  function createTooltipElement({ content, isHtml }: { content: string, isHtml: boolean }) {
    // Create the tooltip element
    // <div class="ztooltip">
    //   <div class="ztooltip-inner">
    //     {{ content here }}
    //   </div>
    //   <div class="ztooltip-arrow" data-popper-arrow />
    // </div>

    const tooltipDiv = document.createElement('div')
    tooltipDiv.className = 'ztooltip'

    const innerDiv = document.createElement('div')
    innerDiv.className = 'ztooltip-inner'
    if (isHtml) {
      innerDiv.innerHTML = content
    }
    else {
      innerDiv.textContent = content
    }
    tooltipDiv.appendChild(innerDiv)

    const arrowDiv = document.createElement('div')
    arrowDiv.className = 'ztooltip-arrow'
    arrowDiv.dataset.popperArrow = ''
    tooltipDiv.appendChild(arrowDiv)

    return tooltipDiv
  }

  const getTooltipPlacement = (modifiers: Modifiers): MODIFIER_KEYS => {
    if (modifiers.top) {
      return MODIFIER_KEYS.top
    }
    else if (modifiers.bottom) {
      return MODIFIER_KEYS.bottom
    }
    else if (modifiers.left) {
      return MODIFIER_KEYS.left
    }
    else if (modifiers.right) {
      return MODIFIER_KEYS.right
    }
    return MODIFIER_KEYS.top
  }

  const isTooltipContentTypeHtml = (modifiers: Modifiers): boolean => {
    return Boolean(modifiers?.html)
  }

  const { isMobileOrTablet } = useDevice()

  const enterEvent = isMobileOrTablet ? 'click' : 'mouseenter'
  const leaveEvent = isMobileOrTablet ? 'document' : 'mouseleave'

  nuxtApp.vueApp.directive('tooltip', {
    beforeMount(el, binding) {
      const content = binding.value?.text || binding.value

      const isHtml = isTooltipContentTypeHtml(binding.modifiers)

      const tooltip = createTooltipElement({ content, isHtml })

      el.popperInstance = null

      const init = () => {
        if (content && !el.popperInstance) {
          el.insertAdjacentElement('afterend', tooltip)

          el.popperInstance = createPopper(el, tooltip, {
            placement: getTooltipPlacement(binding.modifiers),
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 8],
                },
              },
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['top', 'bottom', 'left', 'right'],
                },
              },
              {
                name: 'preventOverflow',
                options: {
                  altAxis: true,
                },
              },
            ],
          })
        }
      }

      const show = () => {
        if (content && el.popperInstance) {
          tooltip.setAttribute('data-show', '')
          el.popperInstance.update()
        }
      }

      const hide = () => {
        if (content) {
          tooltip.removeAttribute('data-show')
        }
      }

      const toggle = () => {
        if (tooltip.hasAttribute('data-show')) {
          hide()
        }
        else {
          show()
        }
      }

      el.clickOutsideEvent = (event: Event) => {
        if (!(el === event.target || el.contains(event.target))) {
          hide()
        }
      }

      el.addEventListener(
        enterEvent,
        (e: Event) => {
          e.preventDefault()
          init()
          toggle()
        },
        false,
      )

      if (leaveEvent === 'document') {
        document.body.addEventListener('click', el.clickOutsideEvent)
      }
      else {
        el.addEventListener(leaveEvent, () => {
          toggle()
        })
      }
    },

    beforeUnmount(el) {
      el.removeEventListener(enterEvent, () => {})
      if (leaveEvent === 'document') {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      }
      else {
        el.removeEventListener(leaveEvent, () => {})
      }
      el?.popperInstance?.destroy()
    },

    // This directive is client-only.
    getSSRProps() {
      return {}
    },
  })
})
