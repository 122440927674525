import { ofetch } from 'ofetch'
import { v4 as uuidv4 } from 'uuid'

import { getSegmentAnonymousId } from '~/lib/tracking/segmentTrack'
import { COOKIES } from '~/constants'

export default defineNuxtPlugin(() => {
  const event = useRequestEvent()
  const cookie = useRequestHeader('cookie')

  const req = event?.node?.req
  const headers = req?.headers ? { ...req.headers } : {}

  const { isLoggedIn } = useAuthentication()
  const { rawToken } = useAuthState()
  const { isMobileOrTablet } = useDevice()
  const platformCookie = useCookie(COOKIES.platform)
  const runtimeConfig = useRuntimeConfig()

  const dataDogHeaders = [
    'x-datadog-trace-id',
    'x-datadog-parent-id',
    'x-datadog-origin',
    'x-datadog-sampling-priority',
  ]

  globalThis.$fetch = ofetch.create({
    onRequest({ request, options }) {
      if (!options?.headers) {
        options.headers = {}
      }

      // Set user-agent when making server side requests
      if (!import.meta.client) {
        if (req && !(options.headers as Record<string, string>)?.common) {
          if (!(options.headers as Record<string, string>)['user-agent'] && !(options.headers as Record<string, string>)['User-Agent']) {
            (options.headers as Record<string, string>)['User-Agent'] = req.headers['user-agent'] as string || req.headers['User-Agent'] as string || 'false'
          }
        }

        if (headers['cf-ipcountry']) {
          (options.headers as Record<string, string>)['RVZ-ipcountry'] = headers['cf-ipcountry'] as string
        }

        if (headers['cf-region']) {
          (options.headers as Record<string, string>)['RVZ-region'] = headers['cf-region'] as string
        }

        if (headers['cf-ipcity']) {
          (options.headers as Record<string, string>)['RVZ-ipcity'] = headers['cf-ipcity'] as string
        }
      }

      // Send the user cookies to the API.
      if (typeof request === 'string' && request.startsWith(runtimeConfig.public.apiUrl)) {
        if (cookie) {
          (options.headers as Record<string, string>)['cookie'] = cookie
        }

        // If the user is logged in, add the Authorization header
        if (isLoggedIn.value && rawToken.value) {
          (options.headers as Record<string, string>)['Authorization'] = `Bearer ${rawToken.value}`
        }

        // Add a unique identifier to each request
        (options.headers as Record<string, string>)['RVZ-Correlation-Id'] = uuidv4() as string

        // Set the platform based on the device type
        (options.headers as Record<string, string>)['Platform'] = platformCookie.value ?? (isMobileOrTablet ? 'mobile_web' : 'web')

        // If on the client, set the anonymous ID and include credentials.
        if (import.meta.client) {
        // Set Segment's anonymousId from cookie or localstorage
          (options.headers as Record<string, string>)['RVZ-Anonymous-Id'] = getSegmentAnonymousId()

          options.credentials = 'include'
        }

        // If the request headers include Datadog trace headers, copy them to the options headers
        if (!import.meta.client) {
          if (req && req.headers) {
            dataDogHeaders.forEach((header) => {
              if (req.headers[header]) {
                (options.headers as Record<string, string>)[header] = req.headers[header] as string
              }
            })
          }
        }
      }
    },
  })
})
