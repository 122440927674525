<template>
  <Html :data-n-head-ssr="isHydrating">
    <LazyAppMaintenance v-if="maintenanceMode" />
    <NuxtLayout v-else>
      <NuxtPage />
      <LazyAppAuth v-if="!isLoggedIn && authenticationModalShown" />
    </NuxtLayout>

    <div id="teleport-target" />
    <ClientOnly>
      <DevTools />
    </ClientOnly>
  </Html>
</template>

<script setup lang="ts">
import '@stripe/stripe-js'
import type { Permission } from './types'
import { trackStartup } from '~/lib/experiments/utils'
import type { SegmentAnalyticsType } from '~/types/segment'

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[]
    analytics: SegmentAnalyticsType
    localStorage: Storage
  }
}

const { maintenanceMode } = useMaintenanceMode()

/**
 * This is added in order to follow the same behaviour as Nuxt2.
 * It's used by the Automated tests to know when hydration is done.
 */
const { hydrationStatus } = useHydrationState()
const isHydrating = computed(() => {
  return hydrationStatus.value ? undefined : true
})

const { authenticationModalShown, isLoggedIn } = useWatchAuthState()
useInitApp()

/**
 * Initializes the app.
 */
function useInitApp() {
  const { t, te } = useI18n()
  const { routeBaseName } = useBaseName()

  const title = computed(() =>
    te(`pages.${routeBaseName.value}.metaTitle`) ? t(`pages.${routeBaseName.value}.metaTitle`) : routeBaseName.value,
  )

  const description = computed(() =>
    te(`pages.${routeBaseName.value}.metaDesc`) ? t(`pages.${routeBaseName.value}.metaDesc`) : '',
  )

  useSeoHeadTags({
    title: () => title.value,
    description: () => description.value,
  })

  const { $moment, $i18n } = useNuxtApp()
  onMounted(async () => {
    const { openSession } = await useBraze()
    const experiments = useExperiments()
    const { isWebView } = usePlatform()

    // Open a new Braze session
    openSession()

    // Track experiments
    trackStartup(experiments.value.evaluated, isWebView.value)
  })

  $moment.locale($i18n.locale)
}

/**
 * Watches the user's auth state and triggers related functionality.
 */
function useWatchAuthState() {
  const runtimeConfig = useRuntimeConfig()
  const { trackUserAuthentication, trackUserDeauthentication } = useTracking()
  const { status, user, isLoggedIn, authenticationModalShown } = useAuthentication()
  const { getCollections, clearCollections } = useFavouriteRVs()
  const permissions = usePermissions()
  const { $experiment } = useNuxtApp()

  /**
   * Identifies the user in the analytics platforms.
   */
  const identifyUser = async () => {
    if (import.meta.client && user.value) {
      // Analytics tracking
      trackUserAuthentication(user.value)

      // Braze tracking
      let userID = user.value.Id.toString()
      if (runtimeConfig.public.braze.userPrefix) {
        userID = `${runtimeConfig.public.braze.userPrefix}_${user.value.Id}`
      }

      const { setUser } = await useBraze()
      setUser(userID)
    }
  }

  const unidentifyUser = () => {
    if (import.meta.client) {
      // Analytics tracking
      trackUserDeauthentication()
    }
  }

  /**
   * Get permissions
   *
   * TODO: this should not happen, we have 3(!) calls to get the user and their
   * properties (/token, /me, /permissions).
   */
  const getPermissions = async () => {
    if (!isLoggedIn.value) {
      permissions.value = []
      return
    }

    const lists: Permission[] = await $fetch(`${runtimeConfig.public.apiUrl}/api/account/me/permissions`)

    permissions.value = lists
  }

  /**
   * Watches the authentication status and fetches the user's list of RV or
   * removes it.
   */
  watch(
    status,
    async (newStatus, previousStatus) => {
      /**
       * User identification
       */
      if (newStatus === 'authenticated') {
        await identifyUser()

        // Get additional user data (getUserExtraInfo)
        await Promise.all([getCollections(), getPermissions()])
      }
      else if (previousStatus && newStatus === 'unauthenticated') {
        unidentifyUser()

        clearCollections()
      }

      if (newStatus !== 'loading') {
        /**
         * Refresh Experiments
         */
        if (previousStatus) {
          $experiment.refreshExperiments()
        }
      }
    },
    {
      immediate: true,
    },
  )

  return { authenticationModalShown, isLoggedIn }
}

const { $i18n, $moment } = useNuxtApp()
watch($i18n.locale, (newLocale) => {
  $moment.locale(newLocale)
})
</script>
