import isBot from '~/lib/isBot.js'
/**
 * This composable calls the Google Maps Geocoder API and returns the result for
 * an address.
 */
export default function useGeocode() {
  const runtimeConfig = useRuntimeConfig()
  const { userAgent } = useDevice()

  // TODO: fix type
  const geocode = async (searchAddress: string) => {
    // Stop the bleed: Prevent Bots to geocoding SearchAddress query params on RV links on POI pages
    // isBot is more flexible than isCrawler
    if (!searchAddress || isBot(userAgent)) {
      return null
    }

    const data = await $fetch<google.maps.GeocoderResponse>(
      `https://maps.googleapis.com/maps/api/geocode/json?key=${runtimeConfig.public.google.mapsApiKey}&address=${encodeURIComponent(
        searchAddress,
      )}&fields=formatted_address,name,geometry,types`,
    )

    return data.results[0]
  }

  return {
    geocode,
  }
}
