import { captureError } from '~/lib/logger'
import type { Collection, Favourite } from '~/types'

/**
 * This composable manages a user's favourited RVs and collections.
 *
 * Question: why do we have separate endpoints for different methods?
 * I.e.: `me/update-collection` instead of `me/collection` with a PUT method.
 */
export default function useFavouriteRVs() {
  const runtimeConfig = useRuntimeConfig()
  const collections = useCollections()
  const { isLoggedIn } = useAuthentication()

  const loading = ref(false)

  const favourites = computed(() => collections.value.map((collection) => collection.Items).flat())

  /**
   * Fetches a user's collections and saves the result in state.
   */
  const getCollections = async () => {
    if (!isLoggedIn.value) return

    if (loading.value) return

    try {
      loading.value = true
      const result: Collection[] = await $fetch(`${runtimeConfig.public.apiUrl}/api/user-profiles/v2/me/get-list-collection`)
      collections.value = result
    }
    catch (error) {
      captureError(error)
    }
    finally {
      loading.value = false
    }
  }

  /**
   * Clears the collections from the state.
   */
  const clearCollections = () => {
    collections.value = []
  }

  /**
   * Creates a new collection and adds it to the state. Optionally adding an RV
   * in the process.
   */
  const createCollection = async (collectionName: string) => {
    if (!isLoggedIn.value) return

    if (loading.value) return

    try {
      loading.value = true
      const collection: Collection = await $fetch(
        `${runtimeConfig.public.apiUrl}/api/user-profiles/v2/me/create-new-collection`,
        {
          method: 'POST',
          body: {
            CollectionName: collectionName,
          },
        },
      )

      collections.value = [...collections.value, collection]

      return collection
    }
    catch (error) {
      captureError(error)
    }
    finally {
      loading.value = false
    }
  }

  /**
   * Updates a collection and saves the result in state.
   */
  const updateCollection = async ({
    collectionID,
    newCollectionName,
  }: {
    collectionID: number
    newCollectionName: string
  }) => {
    if (!isLoggedIn.value) return

    if (loading.value) return

    try {
      loading.value = true
      await $fetch(`${runtimeConfig.public.apiUrl}/api/user-profiles/v2/me/update-collection`, {
        method: 'PUT',
        body: {
          Id: collectionID,
          CollectionName: newCollectionName,
        },
      })

      const collection = collections.value.find((collection) => collection.Id === collectionID)

      if (collection) {
        collection.CollectionName = newCollectionName
      }
    }
    catch (error) {
      captureError(error)
    }
    finally {
      loading.value = false
    }
  }

  /**
   * Deletes a collection and removes it from the state.
   */
  const deleteCollection = async (collectionID: number) => {
    if (!isLoggedIn.value) return

    if (loading.value) return

    try {
      loading.value = true

      await $fetch(`${runtimeConfig.public.apiUrl}/api/user-profiles/v2/me/remove-collection?Id=${collectionID}`, {
        method: 'DELETE',
      })

      collections.value = collections.value.filter((collection) => {
        return collection.Id !== collectionID
      })
    }
    catch (error) {
      captureError(error)
    }
    finally {
      loading.value = false
    }
  }

  /**
   * Adds an RV to a collection and saves the result in state.
   */
  const addRvToCollection = async ({ rvID, collectionID }: { rvID: string, collectionID?: number }) => {
    if (!isLoggedIn.value) return

    if (loading.value) return

    try {
      loading.value = true

      const collectionListID = collectionID || collections.value?.[0]?.Id

      const response: Favourite = await $fetch(`${runtimeConfig.public.apiUrl}/api/user-profiles/v2/me/add-to-favourites-list`, {
        method: 'POST',
        body: {
          RvId: rvID,
          CollectionId: collectionListID,
        },
      })

      collections.value.find((collection) => collection.Id === collectionListID)?.Items?.push(response)
    }
    catch (error) {
      captureError(error)
    }
    finally {
      loading.value = false
    }
  }

  /**
   * Removes an RV from a collection and saves the result in state.
   */
  const removeRvFromCollection = async (favourite: Favourite) => {
    if (!isLoggedIn.value) return

    if (loading.value) return

    try {
      loading.value = true

      await $fetch(`${runtimeConfig.public.apiUrl}/api/user-profiles/v2/me/remove-from-collection?Id=${favourite.Id}`, {
        method: 'DELETE',
      })

      // Remove the favourite from the right collection.
      const itemIndex = collections.value?.find((collection) => collection.Id === favourite?.FavouriteCollectionId)?.Items?.findIndex((item) => item.Id === favourite.Id)

      if (itemIndex !== undefined && itemIndex >= 0) {
        collections.value?.find((collection) => collection.Id === favourite.FavouriteCollectionId)?.Items?.splice(itemIndex, 1)
      }
    }
    catch (error) {
      captureError(error)
    }
    finally {
      loading.value = false
    }
  }

  /**
   * Checks if an RV is favourited in any of the collections.
   */
  const isRvFavourited = (rvId: string) => {
    return favourites.value.find((favourite) => favourite && favourite.RVId === rvId)
  }

  return {
    collections,
    favourites,
    loading,
    getCollections,
    clearCollections,
    createCollection,
    updateCollection,
    deleteCollection,
    addRvToCollection,
    removeRvFromCollection,
    isRvFavourited,
  }
}
